'use strict';
jQuery(function ($) {    
	$('[nearest]').each(function(){
        const container = this,
            $container = $(container); 

        getDealer(function (data) {

            var setVal = $container.attr('setval');
            
            $container.find('[filltext]').each(function () {
                var $el = $(this);
                $el.html(_.at(data, $el.attr('filltext'))[0]);
            });

            [...container.querySelectorAll('[setattr]'),...(container.hasAttribute('setattr') ? [container] : [])]
            .forEach(element => {
                let attrs = element.getAttribute('setattr').split('|');   
                attrs.forEach(set => {
                    let attr = set.split(',');
                    if (attr.length === 2 && data[attr[1]] !== null)
                    {                    
                        element.setAttribute([attr[0]], data[attr[1]]);
                    }
                    else
                    {
                        element.style.display = "none";
                    }
                });      
            });

			$container.find('[appendattr]').each(function(){
				var $el = $(this),
					attr = $el.attr('appendattr').split(','),
					cur = $el.attr(attr[0]);
				$el.attr(attr[0],cur + _.at(data,attr[1])[0]);
            });

            [...container.querySelectorAll('[replaceattr]'),...(container.hasAttribute('replaceattr') ? [container] : [])]
            .forEach(element=> {
				var $el = $(element),
					attr = $el.attr('replaceattr').split(','),
					cur = $el.attr(attr[0]);
                
				$el.attr(attr[0],cur.replace('{value}',_.at(data,attr[1])[0]));
            });

			$container.find('a[directions]').each(function(){
                var $a = $(this);
				$a.attr('href',$.tb.mapURL + encodeURIComponent($a.attr('directions') || $a.text())).attr('target','_blank');
            });

            $container.find('a[google-address]').each(function () {  
                const href = `${$.tb.mapURL}${data.mapAddress.link}`;
                $(this).attr('href', href);
            });

			if(setVal){
				(function(){
					var set = setVal.split(',');
					$container.find(set[0]).val(_.at(data,set[1])[0])
				})();
			}
        });

        function getDealer(fn) {
            // check to see if a cookie with the dealer object already exists
            var cookie = $.tb.cookie.getItem($container.attr("data"));  
            if (cookie !== undefined)
            {
                fn(JSON.parse(cookie));   
                return;
            }

            // check to see if a cookie with the lat/long aleady exists
            cookie = $.tb.cookie.getItem("deviceLocation");  
            if (cookie !== undefined)
            {                
                getDealerFromApi(JSON.parse(cookie), fn);
                return;
            }

            // get geo location
            getGeoLocation(function (response) {                
                $.tb.cookie.setItem('deviceLocation', JSON.stringify({
                    latitude: response.latitude,
                    longitude: response.longitude
                }));
                getDealerFromApi(response, fn);               
            });
            return;
        }

        function getDealerFromApi(data, fn)
        {
            $.ajax({
                url: $container.attr("nearest"),
                dataType: "json",
                localCache: true,
                data: data,
                success: function (response) {
                    const cookie = {  
                        email: response.email,
                        id: response.id,
                        mapAddress: response.mapAddress,
                        name: response.name,
                        phone: response.phone,
                        phoneDisplay: response.phoneDisplay,
                        urlName: response.urlName
                    };                    
                    $.tb.cookie.setItem($container.attr("data"), JSON.stringify(cookie));
                    fn(response);
                },
                error: function (e) {
                    console.log("error occurred under getDealerFromApi() -> " + $container.attr("nearest"), e);
                }
            });
        }

        function getGeoLocation(fn)
        {
            $.ajax({
                url: $.tb.geoUrl,
                dataType: "json",
                localCache: true,
                success: function (response) {
                    fn(response);
                },
                error: function (e) {
                    console.log("error occurred under getGeoLocation() -> " + $.tb.geoUrl, e);
                }
            });
        }
	});
});