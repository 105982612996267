import "./app/_config.js";
import "./app/_htm.js";
import "./app/_queryParams.js";
import "./app/_overflow.js";
import "./app/_elMaybe.js";
import "./app/_setClass.js";
import "./app/_dealerHighlight.js";
import "./app/_scrollNav.js";
import "./app/_slider.js";
import "./app/_scrollTo.js";
import "./app/_global.js";
import "./app/_nav.js";
import "./app/_tabs.js";
import "./app/_trucks.js";
import "./app/_form.js";
import "./app/_dealers.js";
import "./app/_collapsible.js";
import "./app/_press-releases.js";
import "./app/_track-view.js";
import "./app/_sticky.js";
import "./app/_homeSearch.js";
import "./app/_slideshow.js";
import "./app/_iconNav.js";
import "./app/_calc.js";
import "./app/_lazy.js";
import "./app/_nearestDealer.js";
import "./app/_comparison-table.js";
import "./app/_timeline.js";
import "./app/_accordion.js";
import "./app/_tooltip.js";

import domReady from './utils/domReady';

const components = [
	['comp-video', () => import(/* webpackPrefetch: true */'./components/videoInline')],
	['body', () => import(/* webpackPrefetch: true */'./components/contact')],
	['script[type="addressLookup"]', () => import(/* webpackPrefetch: true */'./components/addressLookup')],
	['[data-react]', () => import(/* webpackPrefect: true */'./components/react/index.jsx')],
	['[feature="topStickyNav"]', () => import(/* webpackPrefect: true */'./feature/topStickyNav')],
	['[feature="ddlNav"]', () => import(/* webpackPrefect: true */'./feature/ddlNav')],
	['.swiffy-slider.js', () => import('./feature/swiffy-slider')],
    ['[slide-open]', () => import('./feature/slideOpen')],
    ['[nearest-dealer-map]', () => import('./components/nearestDealerMap')]
];

domReady.then(() => components.forEach(
	async ([elements, loader, combined]) => {
		const elementList = [...document.querySelectorAll(elements)];

		if (combined && elementList.length > 0) {
			const elementLoader = await loader();
			if (elementLoader.default && typeof elementLoader.default === 'function')
				return await elementLoader.default(elementList);
		}

		return elementList.forEach(
			async (element, index) => {
				const l = await loader();
				if (l.default && typeof l.default === 'function')
					l.default(element, index);
			}
		);
	}
));
