'use strict';
(function ( $ ) {
	var h = $.tb.htm,
		key = 'dealerHighlight',
		hlid = $.tb.qs(document.location.search).hl,
		cookie = $.tb.cookie,
		dealerHighlight = cookie.getItem(key) ? JSON.parse(cookie.getItem(key)) : ({
			'cleveland-a': {
				'label': 'SelecTrucks of Cleveland',
				'url': '/centers/selectrucks-of-cleveland/',
				'phone': '866-668-5153',
				'id': '1254'
			},
			'cleveland-b': {
				'label': 'SelecTrucks of Cleveland',
				'url': '/centers/selectrucks-of-cleveland/',
				'phone': '866-674-5360',
				'id': '1254'
			},
			'tampa-a': {
				'label': 'SelecTrucks of Tampa',
				'url': '/centers/selectrucks-of-tampa/',
				'phone': '877-577-9075',
				'id': '1240'
			},
			'tampa-b': {
				'label': 'SelecTrucks of Tampa',
				'url': '/centers/selectrucks-of-tampa/',
				'phone': '877-577-9066',
				'id': '1240'
			}
		})[hlid];

	$.tb.dealerHighlight = dealerHighlight;

	if(hlid){
		cookie.setItem(key, JSON.stringify(dealerHighlight));
	}

	if(dealerHighlight && !$('comp-actions[sticky]')[0]){
		$('body').append(h('div dealer-highlight after-text="'+dealerHighlight.phone+'"',
			h('strong',dealerHighlight.label)
			('a href="tel:'+dealerHighlight.phone+'" target="_self" role="button" call','Call')
		).$);
	}


	


}( jQuery ));