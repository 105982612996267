'use strict';
(function ( $ ) {
	var h = $.tb.htm;

	var autoload = window.location.hash !== '' ? window.location.hash.split('#')[1] : false,
		months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	//http://daimler-trucksnorthamerica.com/news/press-release-detail.aspx#daimler-trucks-north-america-opens-new-2016-04-19


	function formatDate(date){
		return date;
		var d = new Date(date);
		return h('span class="month"',months[d.getMonth()])
				('span class="day"',d.getDate());
	}

	function daysInMonth(month,year) {
		return new Date(year, month, 0).getDate();
	}

	function getRange(str){
		var month, year;
		if(!str){
			return '2001/01/01-'+((new Date()).getFullYear() + 1)+'/01/01';
		} 
		month = str.split('/')[0];
		year = str.split('/')[1];

		return year + '/' + month + '/01-'+ year + '/' + month + '/'+daysInMonth(month,year);
	}

	$('comp-press').each(function(){
        var press = $(this),
            endpoint = press.attr('endpoint'),
            resourcePath = press.attr('resourcepath'),
			count = 10,
			defaultBrand = 'selec_trucks',
			container = press.find('comp-container');


		$('body').on('click','comp-modal[press] button[close]',function(){
			window.location.hash = '';
		});

		container.on('click','button.load-more',function(){
			refreshView(parseInt($(this).attr('page')));
		});

		function refreshView(pageNumber){
			if(typeof pageNumber !== 'number'){
				container.html('');
			}
			else {
				container.find('button.load-more').remove();
			}
			container.addClass('loading');
			$.ajax({
				url: endpoint,
				dataType: 'jsonp',
				jsonpCallback: 'JsonReturn',
				data: {
					brandname: defaultBrand,
					pagesize: count,
					pagenumber: typeof pageNumber === 'number' ? pageNumber : 1,
					daterange: getRange(''),
					requesttype: 'pressrelease'
				}
			}).done(function(data){
				// https://cmspublish-dtna.prd.freightliner.com/bin/dtna/json/service?callback=JsonReturn&requesttype=pressrelease&nodename=western-star-partners-with-namco-to-2016-02-29&_=1456936893028
				container[typeof pageNumber === 'number' ? 'append' : 'html'](data.resultHolder.reduce(function(html,item){
					return html + h('div data-node-name="'+item.pressReleaseNodeName+'"',
									h('span class="date"',formatDate(item.pressReleaseDate))
									('span class="title"',item.pressReleaseTitle));
				},'') + (data.totalResults > data.pageNumber * data.pageSize ? h('button class="load-more" page="'+(data.pageNumber + 1)+'"','Load More') + '' : '') || '<h3>'+container.attr('no-results')+'</h3>').removeClass('loading');
			});
		}

		function loadItem(nodeName,component,after){
			if(component){
				component.addClass('loading');
			}
			$.ajax({
				url: endpoint,
				dataType: 'jsonp',
				jsonpCallback: 'JsonReturn',
				data: {
					nodename: nodeName,
					requesttype: 'pressrelease'
				}
			}).done(function(data){
				var results = data.detailQueryResultHolder;
				if(component){
					component.removeClass('loading');
				}
				if(results && results.pressReleaseArticle){
					window.location.hash = nodeName;


					var $modal = $.tb.elMaybe('body > comp-modal[press]',function(){
							return h('comp-modal press',
								h('header',
									h('button class-toggle="press-active" data-target="html" close')
								)
								('inner-content')
							).$;
						},'body');

					$modal.find('> inner-content').first().html('').append(h('header',
						h('button class="close"')
						('h1',results.pressReleaseTitle)
					)
					('inner-content',
						h('h2',results.pressReleaseTitle)
						('div class="date"',results.pressReleaseDate)
						+results.pressReleaseArticle+
						(results.downloadImages.length ? h('div class="images"',
							h('h4','Images:') + 
							results.downloadImages.reduce(function(html,cur){ 
								return html + h('div class="image"',
									h('img src="'+resourcePath+cur.lowResPath+'"')
									(cur.cutline ? 'h5' : '',cur.cutline)
									(cur.lowResPath ? 'a href="'+resourcePath+cur.lowResPath+'" download' : '','Download Lo-Res File')
									(cur.mediumResPath ? 'a href="'+resourcePath+cur.mediumResPath+'" download' : '','Download Med-Res File')
									(cur.highResPath ? 'a href="'+resourcePath+cur.highResPath+'" download' : '','Download High-Res File')
								);
							},'')
						) : '')
					).$);

					setTimeout(function(){
						$('html').addClass('press-active');
					},10);
			
				}
				if(after){
					after();
				}
			});
		}

		container.on('click','[data-node-name]',function(){
			var n = $(this),
				nodeName = n.attr('data-node-name');
			
			loadItem(nodeName,n);
		});
		
		if(autoload){
			container.html('').addClass('loading');
			loadItem(autoload,false,refreshView);
		}
		else {
			refreshView();
		}

		$(window).on('popstate',()=>{
			let toLoad = window.location.hash.length > 1 ? window.location.hash.split('#')[1] : false;
			if(toLoad){
				container.html('').addClass('loading');
				loadItem(toLoad,false,refreshView);
			}
			else {
				setTimeout(()=>{
					$('html').removeClass('press-active');
				},100);
			}
		});
	});

}( jQuery ));