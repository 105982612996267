'use strict';
(function ( $ ) {
	$.fn.overflow = function() {
		var $self = this;

		function wider(){
			return $self.get(0).scrollWidth > $self.width();
		}
		
		return {
			left: function(){
				if(wider() && $self.scrollLeft() > 0){
					return true;
				}
				return false;
			},
			right: function(){
				if(wider() && $self.get(0).scrollWidth - $self.scrollLeft() > $self.width()){
					return true;
				}
				return false;			
			},
			$: $self
		};
		
	};
}( jQuery ));