'use strict';
jQuery(function($){
	$('comp-slides').each(function(){
		let $slides = $(this),
			cycle = parseInt($slides.attr('cycle') || 0) * 1000;

		$slides.slick({
			dots: true,
			infinite: true,
			arrows: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true,
			autoplay: !!cycle,
			autoplaySpeed: cycle
		}).on('beforeChange',() => {
			setTimeout($.lazyCheck,10);
		});
	});
});