'use strict';
jQuery(function($){
	$('comp-modal[search]').each(function(){
		var $modal = $(this);
		$modal.find('.range-field').slider();

		$modal.on('click','button[type="submit"]',function(){
			$modal.find('form').submit();
		});
	});
});
