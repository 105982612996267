'use strict';
jQuery(function($){
	if(navigator.userAgent.indexOf('MSIE') !== -1){
		$('html').addClass('legacy');
	}
	$('html:first').one('touchstart',function(){
		$(this).addClass('touch');
	});

	$('body').on('click','a[back],a[back-desktop]',function(e){
		e.preventDefault();
		window.history.back();
	});

	// Truck card clicks
	$('body').on('click','> main part-card, > main part-post',function(e){
		var $defaultLink;
		if(['button','a'].indexOf(e.target.tagName.toLowerCase()) === -1){
			$defaultLink = $(this).find('a[default]');
			if(!$defaultLink[0]){
				$defaultLink = $(this).find('a');
			}
			if($defaultLink[0]){
				switch($defaultLink.attr('target')){
					case '_blank':
						window.open($defaultLink.attr('href'),$defaultLink.attr('href'));
						break;
					default: 
					location.href = $defaultLink.attr('href');
				}
			}
		}
	});

	// Class Toggle Clicks
	$('body').on('click','[class-toggle][data-target]',function(e){
		e.preventDefault();
		var $self = $(this),
			targets = $self.attr('data-target').split(','),
			classes = $self.attr('class-toggle').split(',');
		_.zip(targets,classes).forEach(function(item){
			$(item[0] || targets[0]).toggleClass(item[1] || classes[0]);
		});
		$.tb.redraw();
		setTimeout($.lazyCheck,50);
	});

	$('body').on('click','[class-remove][data-target]',function(e){
		e.preventDefault();
		var $self = $(this),
			targets = $self.attr('data-target').split(','),
			classes = $self.attr('class-remove').split(',');
		_.zip(targets,classes).forEach(function(item){
			$(item[0] || targets[0]).removeClass(item[1] || classes[0]);
		});
		$.tb.redraw();
		setTimeout($.lazyCheck,50);
	});

	$('body').on('click','a[href*="#top"]',function(e){
		e.preventDefault();
		$('body > main').animate({
			scrollTop: 0,
			duration: 200
		});
	});

	$('a[directions]').each(function(){
		var $a = $(this);
		$a.attr('href',$.tb.mapURL + encodeURIComponent($a.attr('directions') || $a.text())).attr('target','_blank');
	});

	$('[map]').each(function(){
		var element = this;
		(new google.maps.Geocoder()).geocode( { 'address': $(element).attr('map')}, function(results, status) {
			if (status === google.maps.GeocoderStatus.OK) {
				var location = results[0].geometry.location;

				new google.maps.Marker({
					position: location,
					map: new google.maps.Map(element, Object.assign({
							center: location
						},{
							zoom: 7,
							mapTypeControl: false,
							streetViewControl: false,
							scrollwheel: false,
							draggable: $(document).width() > 767 ? true : false,
							styles: $.tb.mapStyle
						}))
				});
				
			} else {
				console.info("Geocode was not successful for the following reason: " + status);
				//inputAlert(addrEl,'Sorry, we could not find that location.');
			}
		});
	});	

	$('body').on('click','[scroll-to]',function(event){
		event.preventDefault();
		$('main').scrollTo(this.getAttribute('scroll-to'));
	});

	[...document.querySelectorAll('[featured-trucks] > header h2')].forEach(element => element.innerHTML = `<span>${element.innerHTML}</span><span></span>`);

	if (document.querySelectorAll('[display="Campaign"]').length && window.dataLayer) {
		setTimeout(()=>dataLayer.push({'event' : 'Page Engagement', 'action': 'Time on Page', 'label': '30 sec.'}),30000);
	}
});