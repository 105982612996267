'use strict';
(function ( $ ) {
	$.fn.scrollTo = function(selector,duration) {
		var to;
		switch(selector){
			case 'top': 
				to = 0;
				break;
			default: 
				to = this.scrollTop() + $(selector).position().top - $('body > header').height();
		}
		//console.log(to);
		this.animate({
			scrollTop: to,
			duration: duration || 200
		});
	};
}( jQuery ));